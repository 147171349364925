import posthog from "posthog-js";


declare global {
    var eventRecorder: EventRecorder;
}

class EventRecorder {

    active : boolean = false;

    constructor() {
        const env = document.querySelector('script[data-id="eventrecorder"][data-environment]')?.getAttribute('data-environment') || 'development';
        console.debug("EventRecorder initializing in ", env)
        if (env === 'production') {
            posthog.init('phc_89pzUUTE5WpoH17QVjm8ZdL7qcEV2K0T8lIeQELdhTc',
                {
                    api_host: 'https://us.i.posthog.com',
                    person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
                }
            )
            this.active = true;
            console.debug("EventRecorder initialized in production")
        }
        else {
            console.debug("EventRecorder not initialized in", env)
        }
    }

    captureEvent(eventName: string, detail: any | null = null) {
        if (this.active) {
            console.debug("Capturing event ", eventName, detail)
            posthog.capture(eventName, detail)
        } else {
            console.debug("EventRecorder not active, not capturing event ", eventName, detail)
        }
    }
}

globalThis.eventRecorder = new EventRecorder();