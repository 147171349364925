import { initializeHeader } from './header';

document.addEventListener('DOMContentLoaded', () => {
    const env = document.querySelector('script[data-id="app"][data-environment]')?.getAttribute('data-environment') || 'development';

    if (['production', 'staging'].includes(env)) {
        // Sentry is in globals, loaded from CDN
        // @ts-ignore
        Sentry.init({
            environment: env,
        });
    }
    initializeHeader();
})